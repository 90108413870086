import React, { useState } from 'react';
import './style.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { socialMediaConfig } from '../../../configs/configFooter';

/*  This Component for displaying Footer 
*  Defining the data of social media from the config social media  file
 | khaled Mofeed | 29/10/2022 */

const socialMedia = socialMediaConfig;
function Footer() {
  const [readMore, setReadMore] = useState(false);

  return (
    <Row className='justify-content-center align-items-center  footer'>
      <Col md={8} xl={6} className='text-center content-footer'>
        <div className='title-div'>
          <img src={`${process.env.REACT_APP_ASSETS}/img/logo.svg`} alt='footer logo' id='id_logoFooter' />
        </div>
      
        <p className='text-footer' id='id_spanFooter'>
        Token Initial Coin Offerings (ICOs) have become a popular way for blockchain startups to
          raise capital and fund their projects. These ICOs involve the creation and distribution of
          a new cryptocurrency or token, which can be used to access the startup's products or{!readMore && '... '}
          {!readMore ? (
            <span onClick={() => setReadMore(true)}>Read More</span>
          ) : (
            `services. Investors can purchase these tokens during the ICO, typically with established
            cryptocurrencies such as Bitcoin or Ethereum, and hold them for future use or trade them
            on cryptocurrency exchanges.`
          )}
        </p>

        <Row className='justify-content-center align-items-center'>
          <Col lg={7} xl={7} className='text-center'>
            <ul className='social-ul-footer'>
              {socialMedia.map((socialMedia) => (
                <li key={socialMedia.id}>
                  <a
                    href={socialMedia.link}
                    rel='noreferrer'
                    target='_blank'
                    className='linkedin-team'
                    id={socialMedia.id}>
                    <i className={socialMedia.iconClass}></i>
                  </a>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </Col>
      <Col md={10} xl={10} className='footer-information'>
        <Row className='justify-content-center align-items-center'>
          <Col md={3} xl={3} className='text-center d-none d-lg-block'>
            <div className='card-info-footer'>
              <img src={`${process.env.REACT_APP_ASSETS}/img/Button.svg`} alt='Button' id='id_imgCallFooter' />
              <div className='text-info'>
                <p className='title-info' id='id_subTitleFooter'>
                  Have a question?
                </p>
                <p className='feedback-info' id='id_numberFooter'>
                  310-437-2766
                </p>
              </div>
            </div>
          </Col>
          <Col md={3} xl={3} className='text-center d-none d-lg-block'>
            <div className='card-info-footer'>
              <img src={`${process.env.REACT_APP_ASSETS}/img/Button2.svg`} alt='Button' id='id_emailFooter' />
              <div className='text-info'>
                <p className='title-info' id='id_subTitleFooter2'>
                  Contact us at
                </p>
                <p className='feedback-info' id='id_numberFooter2'>
                  unreal@outlook.com
                </p>
              </div>
            </div>
          </Col>
          <Col lg={6} xl={6} className='rights'>
            <p id='id_RightsFooter'>© 2023 Casanova. All Rights Reserved.</p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Footer;
