import React from "react";
import TelvCoin from "../../../assets/home/marketplace/TelvCoin.png";
import VerificationIcon from "../../../assets/home/marketplace/Vector.svg";
import UserImg from "../../../assets/home/marketplace/user.png";
import { Card, Col, Image } from "react-bootstrap";
import { ADMIN } from "../../../constants";
export default function card({ AllNftFillter, imgSrc }) {
  //  this Component for view the data of NFT card | khaled Mofeed
  return (
    <Col md={4} xl={3}>
      <Card className="whole-card mb-3">
        <Card.Img variant="top" src={AllNftFillter?.image || imgSrc} fluid />
        <Card.Body>
          <Card.Title className="card-title">
            {AllNftFillter?.name || "VS-10-123G"}
          </Card.Title>
          <Card.Text>
            <div className="d-flex align-items-center mb-18">
              <div className="user-image">
                <Image src={UserImg} alt="profile picture" />
                <Image
                  className="verification-badge w-14"
                  src={AllNftFillter?.seller?.avatar || VerificationIcon}
                  alt="verified"
                />
              </div>
              <div className="d-flex flex-column">
                <span className="card-owner">Owner</span>
                <span className="card-owner-name">
                  {AllNftFillter?.seller?.name || ADMIN}
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <Image
                  className="telv-symbol"
                  src={TelvCoin}
                  alt="TelvCoin Symbol"
                />
                <span className="card-price">
                  {AllNftFillter?.price} {AllNftFillter?.symbol}
                </span>
              </div>
              <span className="place-bid-btn">Place Bid</span>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
}
