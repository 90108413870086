import { useCallback, useContext, useState } from "react";
import { AppContext } from "../scripts/context";
import { useQuery } from "react-query";
import { request } from "../admin/plugins/axios";
import { endpoints } from "../utils/networks";
import { ethers } from "ethers";
import config from "../configs";
import { toast } from "react-hot-toast";
import { convertTotimestamp } from "../Helper/Helper";

const marketplaceAddress = config.get("smartContract.MARKETPLACE_ADDRESS");
const nftMarketplaceAddress = config.get(
  "smartContract.NFT_MARKETPLACE_ADDRESS"
);

export const useLandData = (id) => {
  const { method, url } = endpoints.Marketplace.NftData;
  const { refetch, data, isLoading, error } = useQuery(["landData", id], () =>
    request(method, url(id))
  );
  return { refetch, data, isLoading, error };
};

export const useMarketplacePurchase = () => {
  const [isBuyLoading, setIsBuyLoading] = useState(false);
  const [buyError, setBuyError] = useState(null);
  const [buyTxHash, setBuyTxHash] = useState(null);
  const [buyTxReceipt, setBuyTxReceipt] = useState(null);
  const [buyTxError, setBuyTxError] = useState(null);
  const [buyTxStatus, setBuyTxStatus] = useState(null);
  const {
    state: {
      user: { address: account },
      contracts: {
        marketplace: { contract: landSaleContract },
        token: { contract: tokenContract },
      },
    },
  } = useContext(AppContext);

  const buy = useCallback(
    async (tokenId, price) => {
      try {
        setIsBuyLoading(true);
        setBuyError(null);
        setBuyTxHash(null);
        setBuyTxReceipt(null);
        setBuyTxError(null);
        setBuyTxStatus(null);

        let totalPay = +price * 10 ** 16;
        totalPay = ethers.utils.parseUnits(price.toString(), 16);

        let allowanceBalance = await tokenContract.allowance(
          account,
          marketplaceAddress
        );

        if (!(Number(allowanceBalance._hex) >= price)) {
          console.log("Approving");
          const state = await tokenContract
            .approve(
              marketplaceAddress,
              "0x" + Number(1000000 * 10 ** 16).toString(16)
            )
            .then(async (data) => {
              return true;
            })
            .catch((e) => {
              console.log(JSON.parse(JSON.stringify(e)).reason);
              toast.error("something went wrong");
              return false;
            });
          if (!state) {
            return;
          }
        }

        const tx = await landSaleContract.buy(tokenId, totalPay);

        setBuyTxHash(tx.hash);

        const receipt = await tx.wait();

        setBuyTxReceipt(receipt);

        if (receipt.status === 1) {
          setBuyTxStatus("success");
        } else {
          setBuyTxStatus("fail");
          setBuyTxError("Transaction failed");
        }
      } catch (error) {
        let errMessage = "Transaction failed";
        if (error.message.includes("nft already sold")) {
          errMessage = "nft already sold";
        }
        if (error.message.includes("nft not for sale")) {
          errMessage = "nft not for sale";
        }
        if (error.message.includes("transfer amount exceeds balance")) {
          errMessage = "Insufficient balance";
        }
        if (error.message.includes("transfer amount exceeds allowance")) {
          errMessage = "Insufficient allowance";
        }
        setBuyTxError(errMessage);
        throw error;
      } finally {
        setIsBuyLoading(false);
      }
    },
    [landSaleContract, account, tokenContract]
  );

  return {
    isBuyLoading,
    buyError,
    buyTxHash,
    buyTxReceipt,
    buyTxError,
    buyTxStatus,
    buy,
  };
};

export const useBalanceOf = () => {
  const {
    state: {
      user: { address: account },

      contracts: {
        token: { contract: tokenContract },
      },
    },
  } = useContext(AppContext);
  const { data, isLoading, error } = useQuery(
    ["balanceOf", account],
    () => tokenContract.balanceOf(account),
    {
      refetchInterval: 10000,
    }
  );
  return { data, isLoading, error };
};

export const useMarketplaceOffer = () => {
  const [isOfferLoading, setIsOfferLoading] = useState(false);
  const [offerError, setOfferError] = useState(null);
  const [offerTxHash, setOfferTxHash] = useState(null);
  const [offerTxReceipt, setOfferTxReceipt] = useState(null);
  const [offerTxError, setOfferTxError] = useState(null);
  const [offerTxStatus, setOfferTxStatus] = useState(null);
  const {
    state: {
      contracts: {
        marketplace: { contract: landSaleContract },
      },
    },
  } = useContext(AppContext);

  const offer = useCallback(
    async (tokenId, offerPrice, duration) => {
      try {
        setIsOfferLoading(true);
        setOfferError(null);
        setOfferTxHash(null);
        setOfferTxReceipt(null);
        setOfferTxError(null);
        setOfferTxStatus(null);

        console.log("data", tokenId, offerPrice, duration, "data");

        let totalPay = +offerPrice * 10 ** 16;
        totalPay = ethers.utils.parseUnits(offerPrice.toString(), 16);

        const dateIntimestamp = convertTotimestamp(duration);

        console.log(
          "dataModifiex",
          nftMarketplaceAddress,
          tokenId,
          totalPay,
          dateIntimestamp,
          "dataModifiex"
        );

        const tx = await landSaleContract
          .makeOffer(nftMarketplaceAddress, tokenId, totalPay, dateIntimestamp)
          .then((res) => res)
          .catch((e) => {
            toast.error(JSON.parse(JSON.stringify(e)).reason);
          });

        setOfferTxHash(tx.hash);

        const receipt = await tx.wait();

        setOfferTxReceipt(receipt);

        if (receipt.status === 1) {
          setOfferTxStatus("success");
        } else {
          setOfferTxStatus("fail");
          setOfferTxError("Transaction failed");
        }
      } catch (error) {
        setOfferTxStatus("fail");
        let errMessage = "Transaction failed";
        if (error.message.includes("nft already sold")) {
          errMessage = "nft already sold";
        }
        if (error.message.includes("nft not for sale")) {
          errMessage = "nft not for sale";
        }
        if (error.message.includes("transfer amount exceeds balance")) {
          errMessage = "Insufficient balance";
        }
        if (error.message.includes("transfer amount exceeds allowance")) {
          errMessage = "Insufficient allowance";
        }
        setOfferTxError(errMessage + "2323");
        throw error;
      } finally {
        setIsOfferLoading(false);
      }
    },
    [landSaleContract]
  );

  return {
    isOfferLoading,
    offer,
    offerError,
    offerTxHash,
    offerTxReceipt,
    offerTxError,
    offerTxStatus,
  };
};

// this hook is used to perform actions about resaling nft
export const useResale = () => {
  const {
    state: {
      contracts: {
        marketplace: { contract: marketplace },
        nftMarketplace: { contract: nftMarketplaceContract },
      },
    },
  } = useContext(AppContext);

  // this function is used to resale nft for the first time
  const resale = useCallback(
    async (tokenId, price, isOpenResale, isOpenMakeOffer) => {
      let priceInWei = ethers.utils.parseUnits(price.toString(), 16);
      let address = await nftMarketplaceContract.getApproved(tokenId);
      console.log(address, "address");

      if (address === "0x0000000000000000000000000000000000000000") {
        console.log("got in address");
        await nftMarketplaceContract
          .approve(marketplaceAddress, tokenId)
          .then(async (data) => {
            console.log("data", data);
            console.log("Successfully placed");
          })
          .catch((e) => {
            console.log("error", e);
            console.log(JSON.parse(JSON.stringify(e)).reason);
          });
      } else {
        marketplace
          .resale(tokenId, priceInWei, isOpenResale, isOpenMakeOffer)
          .then((data) => {
            console.log(price, "price");
            console.log(priceInWei, "priceInWei");
            toast.success("Successfully placed");
          })
          .catch((e) => {
            toast.error(JSON.parse(JSON.stringify(e)).reason);
          });
      }
    },
    [nftMarketplaceContract, marketplace]
  );

  // this function is used to change the capibalitiy of nft to accept offer
  const makeAcceptOffer = useCallback(
    async (tokenId, isOpenMakeOffer) => {
      try {
        const tx = await marketplace.setMakeOffer(tokenId, isOpenMakeOffer);

        const receipt = await tx.wait();

        if (receipt.status === 1) {
          toast.success("Changed the accept offer status successfully");
        } else {
          toast.error("Transaction failed");
        }
      } catch (error) {
        toast.error("Transaction failed");
        console.log(error);
      }
    },
    [marketplace]
  );

  // this function is used to change the price of nft
  const setPrice = useCallback(
    async (tokenId, price, isForSale) => {
      try {
        const tx = await marketplace.setPrice(tokenId, price, isForSale);

        const receipt = await tx.wait();

        if (receipt.status === 1) {
          toast.success("Changed the price successfully");
        } else {
          toast.error("Transaction failed");
        }
      } catch (error) {
        toast.error("Transaction failed");
        console.log(error);
      }
    },
    [marketplace]
  );

  return {
    resale,
    makeAcceptOffer,
    setPrice,
  };
};

// this hook is used for fetching the purchase history from backend
export const usePurchaseHistory = (id, page) => {
  const limit = 3;
  const { method, url } = endpoints.Marketplace.NftPurchaseHistory;
  const { refetch, data, isLoading, error } = useQuery(
    ["landPurchaseHistory", "token_id", id],
    () =>
      request(method, url(id), {
        params: {
          per_page: limit,
          page,
        },
      })
  );
  return { refetch, data, isLoading, error };
};

// this hook is used for fetching the latest offers from backend
export const useLatestOffers = (id, page) => {
  if (page === undefined) {
    page = 1;
  }

  const limit = 3;
  const { method, url } = endpoints.Marketplace.NftOffers;
  const { refetch, data, isLoading, error } = useQuery(
    ["landOffes", "token_id", id],
    () =>
      request(method, url(id), {
        params: {
          per_page: limit,
          page,
        },
      })
  );
  return { refetch, data, isLoading, error };
};

//this hook is used for actions like accept offer, cancel offer, and handeling it the status in the states
export const useActions = () => {
  const {
    state: {
      contracts: {
        marketplace: { contract: marketplace },
      },
    },
  } = useContext(AppContext);

  const [isOfferLoading, setIsOfferLoading] = useState(false);
  const [offerTxHash, setOfferTxHash] = useState(null);
  const [offerTxError, setOfferTxError] = useState(null);
  const [offerTxStatus, setOfferTxStatus] = useState(null);

  // here is the function that is called when the user accepts an offer
  // ex: accept(1, '0x1234567890')
  // tokenId = 1
  // offeror = '0x1234567890'
  const accept = useCallback(
    async (tokenId, offeror) => {
      try {
        setIsOfferLoading(true);
        setOfferTxStatus(null);

        const tx = await marketplace.acceptOfferNFT(tokenId, offeror);

        setOfferTxHash(tx.hash);

        const receipt = await tx.wait();

        if (receipt.status === 1) {
          setOfferTxStatus("success");
        } else {
          setOfferTxStatus("fail");
          setOfferTxError("Transaction failed");
        }
      } catch (error) {
        setOfferTxStatus("fail");
        const errMessage = JSON.parse(JSON.stringify(error)).reason;
        setOfferTxError(errMessage);
        throw error;
      } finally {
        setIsOfferLoading(false);
      }
    },
    [marketplace]
  );

  // here is the function that is called when the user cancels an offer
  // ex: accept(1)
  // tokenId = 1
  const cancel = useCallback(
    async (tokenId) => {
      try {
        setIsOfferLoading(true);
        setOfferTxStatus(null);

        const tx = await marketplace.cancelOffer(tokenId);

        setOfferTxHash(tx.hash);

        const receipt = await tx.wait();

        if (receipt.status === 1) {
          setOfferTxStatus("success");
        } else {
          setOfferTxStatus("fail");
          setOfferTxError("Transaction failed");
        }
      } catch (error) {
        setOfferTxStatus("fail");
        const errMessage = JSON.parse(JSON.stringify(error)).reason;
        setOfferTxError(errMessage);
        throw error;
      } finally {
        setIsOfferLoading(false);
      }
    },
    [marketplace]
  );

  return {
    isOfferLoading,
    offerTxHash,
    offerTxError,
    offerTxStatus,
    accept,
    cancel,
  };
};
