import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import { Button } from '@mui/material';
import { Button as ButtonBootstrap } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { CLOSE_ICON } from '../../../../constants';
import StyledNumberInput from './inputs/Number';
import DateTimePicker from 'react-datetime-picker';
import { pxToRem } from '../theme';
import './style.css';
import { useBalanceOf } from '../../../../hooks/useMarketplace';
import { useDebounce } from '../../../../Helper/Helper';
import { toast } from 'react-hot-toast';

function OfferModal({ data, offer, ...props }) {
  const [selectedDate, setSelectedDate] = useState();
  const [price, setPrice] = useState(0);

  let { data : balance} = useBalanceOf()
  balance = balance ? Number(balance?._hex ) / 10 ** 16 : 0;
  
  const handleChange = (e) => {
    const { value } = e.target;
    setPrice(+value);
  };
  const handleOffer = () => {
    if (!price) {
      return toast.error('Please enter price');
    }
    if(price > balance){
      return toast.error('Insufficient balance')
    }
    if (price > data.price) {
      return toast.error('Price should be less than the current price');
    }
    
    offer(data.token_id ,price, selectedDate);
  };


  const debouncedMakingOffer = useDebounce(handleOffer, 1000);

  return (
    <Modal
      {...props}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className='nftOfferModal'>
      <Modal.Header className='border-0 d-flex align-items-center justify-content-between'>
        <Modal.Title id='contained-modal-title-vcenter'>
          <h4>Place an Offer</h4>
        </Modal.Title>
        <ButtonBootstrap onClick={props.onHide} variant='outline-secondary closeModal'>
          <img src={CLOSE_ICON} alt='close' className='closeModal' />
        </ButtonBootstrap>
      </Modal.Header>
      <Modal.Body className='d-flex flex-column gap-4'>
        <Row>
          <p>You are about to place a bit for place an offer NFT #A14322.</p>
        </Row>
        <Row>
          <div className='d-flex justify-content-between align-items-center'>
            <span>Your offer</span>

            <StyledNumberInput
              name='price'
              value={price}
              onChange={(e) => {
                const parsed = Number(e.target.value);
                if (typeof parsed === 'number' && !Number.isNaN(parsed)) {
                  return handleChange(e);
                }
              }}
            />
          </div>
        </Row>
        <Row>
          <DateTimePicker
            value={selectedDate}
            onChange={setSelectedDate}
            className='w-100'
            calendarClassName='Selectdatetime'
            minDate={new Date()}
            // locale='English'
            format='yyyy-MM-dd'
          />
        </Row>
        <Row>
          <ul>
            <li className='d-flex justify-content-between align-items-center'>
              <p>Offering</p>
              <span>{price} TELV</span>
            </li>
            <li className='d-flex justify-content-between align-items-center'>
              <p>Your balance</p>
              <span>{balance} TELV</span>
            </li>
            <li>
              <hr />
            </li>
          </ul>
        </Row>
        <Row>
          <Button
            onClick={debouncedMakingOffer}
            size='large'
            sx={{
              borderRadius: pxToRem(85),
              marginBottom: pxToRem(12),
            }}
            fullWidth
            variant='contained'
            color='primary'
            >
            Place an Offer
          </Button>
          <Button
            onClick={props.onHide}
            size='large'
            sx={{
              color: 'white',
              border: '1.5px solid #777E90',
              borderRadius: pxToRem(85),
              marginBottom: pxToRem(12),
            }}
            fullWidth
            // variant='contained'
            // color='primary'
            >
            close
          </Button>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default OfferModal;
