import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { copyToClipboard } from "../../../Helper/Helper";
import { useUploadImage } from "../Hooks/useUploadImage";
import EditAvatarProfile from "./EditAvatarProfile";
function SideBarProfile() {
  // the Left side bar of profile display
  //avatar ,
  // name,
  //bio,
  //custom_url,
  // social media links
  // | khaled Mofeed
  const { userData } = useUploadImage("avatar");
  function copyAdressToClipboard() {
    copyToClipboard(userData?.wallet_address);
  }
  // Status for show more and show less in bio lable || Khaled Mofeed
  const [showMore, setShowMore] = useState(false);
  // truncatedBio variable to show just 100 characters of bio lable || Khaled Mofeed
  const truncatedBio = userData?.bio?.slice(0, 100);
  // handleShowMore function to handle show more or less than 100 characters of bio lable || Khaled Mofeed
  const handleShowMore = () => {
    setShowMore(!showMore);
  };
  return (
    <div className="cardLeftProfile">
      <Row className="pt-4 pb-4 mt-1 mb-4">
        <Col md={12} xl={12} className="text-center mb-3">
          <EditAvatarProfile />
        </Col>
        <Col md={12} xl={12} className="px-5 mb-2">
          <h5 className="nickName">{userData?.name}</h5>
          <p className="addressLable">
            {userData?.wallet_address &&
              userData?.wallet_address.substring(0, 10) +
                "..." +
                userData?.wallet_address.substring(
                  userData?.wallet_address.length - 4
                )}
            <Link className="copyBtn">
              <img
                src={`${process.env.REACT_APP_ASSETS}/img/copy.svg`}
                onClick={copyAdressToClipboard}
                alt="copy"
              />
            </Link>
          </p>
          <p className="descriptionLable" id="descriptionLable">
            {showMore ? userData?.bio : truncatedBio}{" "}
            {userData?.bio?.length > 100 && !showMore && "... "}
            {userData?.bio?.length > 100 && (
              <span onClick={handleShowMore}>
                {showMore ? "See Less" : "See More"}
              </span>
            )}
          </p>
          {userData?.custom_url && (
            <a
              target="_blank"
              href={userData?.custom_url}
              className="hrefLable"
              rel="noreferrer"
            >
              <img src={`${process.env.REACT_APP_ASSETS}/img/global.svg`} alt="global" className="global" />{" "}
              {userData?.custom_url &&
                userData?.custom_url.substring(0, 20) + "..."}
            </a>
          )}
        </Col>
        <Col md={12} xl={12} className="px-5 mt-3 d-flex">
          <Button
            variant="primary"
            className="custom-primary btnFollow"
            id="id_Follow"
          >
            Follow
          </Button>
          <Button
            variant="secondary"
            className="custom-primary btnUpload"
            id="id_upload1"
          >
            <img src={`${process.env.REACT_APP_ASSETS}/img/upload.svg`} alt="upload" className="upload" />
          </Button>
        </Col>

        <Col md={12} xl={12} className="px-5 mt-3 ">
          <hr className="hr" />
        </Col>
        <Col md={12} xl={12} className="px-5 mt-3 d-flex">
          <ul className="shareUl">
            <li key="twitter">
              <a href={userData?.twitter} target="_blank" rel="noreferrer">
                <img
                  src={`${process.env.REACT_APP_ASSETS}/img/twitter.svg`}
                  alt="twitter"
                  className="twetterLink"
                />
              </a>
            </li>
            <li key="facebook">
              <a href={userData?.facebook} target="_blank" rel="noreferrer">
                <img
                  src={`${process.env.REACT_APP_ASSETS}/img/facebok.svg`}
                  alt="facebook"
                  className="twetterLink"
                />
              </a>
            </li>
            <li key="portfolio">
              <a href={userData?.portfolio} target="_blank" rel="noreferrer">
                <img
                  src={`${process.env.REACT_APP_ASSETS}/img/portfolio.svg`}
                  alt="portfolio"
                  className="twetterLink"
                />
              </a>
            </li>
            <li key="linkedin">
              <a href={userData?.linkedin} target="_blank" rel="noreferrer">
                <img
                  src={`${process.env.REACT_APP_ASSETS}/img/linkedin.svg`}
                  alt="linkedin"
                  className="twetterLink"
                />
              </a>
            </li>
            <li key="instagram">
              <a href={userData?.instagram} target="_blank" rel="noreferrer">
                <img
                  src={`${process.env.REACT_APP_ASSETS}/img/instagram.svg`}
                  alt="instagram"
                  className="twetterLink"
                />
              </a>
            </li>
          </ul>
        </Col>
        <Col md={12} xl={12} className="px-5 mt-1 ">
          <p className="since text-center">
            Member since {userData?.register_date}
          </p>
        </Col>
      </Row>
    </div>
  );
}

export default SideBarProfile;
