import React from "react";
import { InputBase } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledInput = styled(InputBase)(({ theme }) => ({
  border: "1px solid #343444",
  padding: "12px 16px",
  borderRadius: "1rem",
  height: "48px",
  fontSize: "1rem",
  lineHeight: "24px",
  fontFamily: theme.typography.fontFamily,
}));

export default StyledInput;
