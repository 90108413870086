import React, { useContext } from "react";
import Card from "../components/Card";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { AppContext } from "../../../scripts/context";
import Pagenation from "./Pagination";
function NftMarketPlace(props) {
  // this is the Container of MarketPlace Page components have:
  // Card, Component for view the data of NFT card
  // Pagenation ,Component used for render the data from Api by pagination
  // | khaled Mofeed
  const { ownerOfNft } = useContext(AppContext);
  const AllNftFillterData = ownerOfNft.ownerOfNft;
  return (
    <>
      <Row className="marketplace-list ProfileCard">
        {AllNftFillterData?.map((AllNftFillter) => (
          <Card imgSrc="/img/card1.svg" AllNftFillter={AllNftFillter} />
        ))}
        <Card imgSrc={`${process.env.REACT_APP_ASSETS}/img/card1.svg`} /> 
        <Card imgSrc={`${process.env.REACT_APP_ASSETS}/img/card2.svg`} />
        <Card imgSrc={`${process.env.REACT_APP_ASSETS}/img/card3.svg`} />
        <Card imgSrc={`${process.env.REACT_APP_ASSETS}/img/card4.svg`} />
        <Card imgSrc={`${process.env.REACT_APP_ASSETS}/img/card5.svg`} />
        <Card imgSrc={`${process.env.REACT_APP_ASSETS}/img/card4.svg`} />
        <Card imgSrc={`${process.env.REACT_APP_ASSETS}/img/card6.svg`} />
        <Card imgSrc={`${process.env.REACT_APP_ASSETS}/img/card7.svg`} />
      </Row>
      <Pagenation />
    </>
  );
}

export default NftMarketPlace;
