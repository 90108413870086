import { Col, Container, Image, Row } from "react-bootstrap";
import { useState } from "react";
import "./index.css";
import signinImage from "../../assets/metamask.png";

function ForgotPassword() {
  const handleForgetPassword = () => {};
  return (
    <Container fluid>
      <Row className="justify-content-center DistributionVesting min-height-100per align-items-center">
        <Col md={10} xl={10} className="mb-5">
          <Row className="justify-content-center flex-nowrap flex-lg-row flex-column-reverse gap-5 align-items-center">
            <Col lg={5} sm={10}>
              <div className="d-flex flex-column sign-form">
                <div className=" d-flex flex-column gap-4">
                  <h2 className="form-title">Reset Your Password</h2>
                  <div className="form-input-container d-flex flex-column">
                    <label htmlFor="email">email</label>
                    <input
                      type="email"
                      id="email"
                      placeholder="Enter your email"
                    />
                  </div>
                  <button className="form-btn" onClick={handleForgetPassword}>
                    Send Email
                  </button>
                </div>
              </div>
            </Col>
            <Col lg={7} sm={10} className="d-flex justify-content-center">
              <Image
                src={signinImage}
                className="sign-img"
                width={500}
                height={470}
                alt="signin"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default ForgotPassword;
