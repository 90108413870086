import React from 'react';
import styles from './NewsTicker.module.css';

const NewsTicker = () => {
    const myNewsItems = [
        ' Dear user, Please be advised that the application you are currently using is a trial version of our product and not a fully functioning application. ',
        'This trial version is designed to showcase the features and capabilities of our product for demonstration purposes only.',
        'visit us on mcti.io for more information',
      ];

    // duplicate the array 100 times to create a continuous loop
    let newsItems = [];
    for (let i = 0; i < 100; i++) {
        newsItems.push(...myNewsItems);
    }

  return (
    <div className={styles.newsTicker}>
      <div className={styles.newsTickerItems}>
        {newsItems.map((item, index) => (
          <div key={index} className={styles.newsItem}>
            <a href='https://mcti.io' target='_blank' rel='noopener noreferrer'>
              {item}
            </a>
          </div>
        ))}
        {newsItems.map((item, index) => (
          <div key={index} className={styles.newsItem}>
            <a href='https://mcti.io' target='_blank' rel='noopener noreferrer'>
              {item}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewsTicker;
