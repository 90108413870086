import { Col, Container, Image, Row } from "react-bootstrap";
import { useState } from "react";
import "./index.css";
import signinImage from "../../assets/metamask.png";
import { useNavigate } from "react-router-dom";
function SignPage() {
  const [isSignIn, setIsSignIn] = useState(false);
  const navigate = useNavigate();
  const handleSwitch = () => {
    setIsSignIn(!isSignIn);
  };
  const handleSignIn = () => {};
  const handleSignUp = () => {};
  const handleForgetPassword = () => {
    navigate("/forgot-password");
  };
  return (
    <Container fluid>
      <Row className="justify-content-center DistributionVesting min-height-100per align-items-center">
        <Col md={10} xl={10} className="mb-5">
          <Row className="justify-content-center flex-nowrap flex-lg-row flex-column-reverse gap-5 align-items-center">
            <Col lg={5} sm={10}>
              {isSignIn ? (
                <div className="d-flex flex-column sign-form">
                  <div className=" d-flex flex-column gap-4">
                    <h2 className="form-title">Sign In</h2>
                    <div className="form-input-container d-flex flex-column">
                      <label htmlFor="email">email</label>
                      <input
                        type="email"
                        id="email"
                        placeholder="Enter your email"
                      />
                    </div>
                    <div className="form-input-container d-flex flex-column">
                      <label htmlFor="password">password</label>
                      <input
                        type="password"
                        id="password"
                        placeholder="Enter your password"
                      />
                    </div>
                    <div className="form-input-container d-flex flex-column">
                      <p
                        className="forgot-password"
                        onClick={handleForgetPassword}>
                        Forgot your password?
                      </p>
                    </div>
                    <button className="form-btn" onClick={handleSignIn}>
                      Sign in
                    </button>
                  </div>
                  <p
                    className="form-p"
                    onClick={
                      handleSwitch
                    }>{`You don't have an account yet? Sign up`}</p>
                </div>
              ) : (
                <div className="sign-form">
                  <div className=" d-flex flex-column gap-4">
                    <h2 className="form-title">Sign Up</h2>
                    <div className="form-input-container d-flex flex-column">
                      <label htmlFor="firstName">first name</label>
                      <input
                        id="firstName"
                        type="text"
                        placeholder="Enter your first name"
                      />
                    </div>
                    <div className="form-input-container d-flex flex-column">
                      <label htmlFor="lastName">last name</label>
                      <input
                        id="lastName"
                        type="text"
                        placeholder="Enter your last name"
                      />
                    </div>
                    <div className="form-input-container d-flex flex-column">
                      <label htmlFor="username">username</label>
                      <input
                        id="username"
                        type="text"
                        placeholder="Enter your username"
                      />
                    </div>
                    <div className="form-input-container d-flex flex-column">
                      <label htmlFor="email">email</label>
                      <input
                        type="email"
                        id="email"
                        placeholder="Enter your email"
                      />
                    </div>
                    <div className="form-input-container d-flex flex-column">
                      <label htmlFor="password">password</label>
                      <input
                        id="password"
                        type="password"
                        placeholder="Enter your password"
                      />
                    </div>
                    <div className="form-input-container d-flex flex-column">
                      <label htmlFor="cPassword">confirm password</label>
                      <input
                        id="cPassword"
                        type="password"
                        placeholder="Confirm your password"
                      />
                    </div>
                    <button className="form-btn" onClick={handleSignUp}>
                      Sign Up
                    </button>
                  </div>
                  <p className="form-p" onClick={handleSwitch}>
                    You already have an account? Sign in
                  </p>
                </div>
              )}
            </Col>
            <Col lg={7} sm={10} className="d-flex justify-content-center">
              <Image
                src={signinImage}
                className="sign-img"
                width={500}
                height={470}
                alt="signin"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default SignPage;
