import { styled, SxProps, Theme } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";
import React from "react";
import { pxToRem } from "../theme";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";

const StyledNFTActionsCard = styled("div")(({ theme }) => ({
  background: "#1E1F25",
  borderRadius: pxToRem(20),
  padding: `${pxToRem(34)} ${pxToRem(24)} ${pxToRem(23)} ${pxToRem(23)}`,
  [theme.breakpoints.up("md")]: {
    maxWidth: pxToRem(450),
  },
}));

const InfoCard = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  padding: `${pxToRem(19)} ${pxToRem(22)}`,
  border: "1px solid #343444",
  borderRadius: "1rem",
  marginBottom: pxToRem(25),
}));

const InfoItem = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

const NFTActionsCard = (props) => {
  return (
    <StyledNFTActionsCard sx={props.sx}>
      <InfoCard>
        <InfoItem>
          <Typography component="p" variant="body4" color="darkgray">
            Price
          </Typography>
          <Typography component="p" variant="h7">
            {props.price}
          </Typography>
        </InfoItem>
        <InfoItem>
          <Typography component="p" variant="body4" color="darkgray">
            Network
          </Typography>
          <Typography component="p" variant="h7">
            Binance
          </Typography>
        </InfoItem>
      </InfoCard>
      {props.isShowBuy && (
        <Button
          onClick={props.onClick}
          size="large"
          sx={{
            borderRadius: pxToRem(85),
            marginBottom: pxToRem(12),
          }}
          fullWidth
          variant="contained"
          color="primary"
        >
          Place an Offer
        </Button>
      )}
      {props.isShowBuy && (
        <Button
          size="large"
          fullWidth
          sx={{
            borderRadius: pxToRem(85),
            marginBottom: pxToRem(34),
          }}
          variant="contained"
          color="primary"
          onClick={props.onBuy}
        >
          Buy
        </Button>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <AccessTimeOutlinedIcon
          sx={{
            marginRight: `${pxToRem(9)}`,
            color: "darkgray",
          }}
        />
        <Typography color="darkgray" variant="body4" component="p">
          Expires in 3 months
        </Typography>
      </div>
    </StyledNFTActionsCard>
  );
};

export default NFTActionsCard;
